@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap" rel="stylesheet"');
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap" rel="stylesheet");
.card{
  grid-area: business_card;
  background-color: #1D1E26;
  box-shadow: 15px 0px 8px -4px rgba(50, 50, 50, 0.75);
}
.title{
  padding-top: 50px;
  text-align: center;
}
.title h2{
  color:white;
  font-family: 'Raleway', sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
}
.title img{
  height: 150px;
  border-radius:50%;
  margin-bottom: 15px;
}
.title span{
  color:white;
  font-size:20px;
  font-family: 'Roboto';
}
.title i {
  color:white;
  margin-left:5px;
}

.parallax__elem1 {
  margin-top:20px;
  height:30px;
  margin-left:10px;
}
.parallax__elem2 {
  float: right;
  position: absolute;
  color:red;
  margin-top: 200px;
  height:30px;
}
.parallax__elem3{
  position: absolute;
  margin-top: 100px;
  height: 40px;
  right: 0;
}
.parallax__elem4{
  position: absolute;
  top: 400px;
  right: 0;
  height:50px;
}
.block_button{
  border:1px solid red;
  width:180px;
}
@media all and (max-width: 575px) {
  .parallax__elem4{
    display: none;
  }

}
