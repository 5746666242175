.button_card::before{
  background-image: repeating-linear-gradient(to left, #242961, #194A7A);
  border-radius: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .5s ease-in-out;
}

.button_card:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}
.button_card{
  margin-top:40px;
  transform: translate(0%, 0%);
  min-width: 150px;
  height: 40px;
  text-align: center;
  font-family: sans-serif;
  color: #fff;
  text-decoration: none;
  letter-spacing: 4px;
  transition: .5s;
  background: linear-gradient(to left, #EC6448,#E93F35);
  border:none;
}
.button_header{
  margin-left:30px;
  margin-top:-1px;
  padding-left:2px;
  font-size: 18px;
  position: relative;
  transition: all 0.6s;
  border:none;
  color: black;
  background-color:#fff;
}
.button_header::before{
  content: "";
  width: 0;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  left: 0;
  background: blue;
  transition: all 0.6s;
}
.button_header:hover::before{
  width: 100%;
  left: 0;
  background: #EC6448;
}
.btn[disabled] {
  opacity: .5 ;
  cursor: not-allowed;
  color:white;
  border:none;
  border-radius: 0;
}
.btn[disabled]:hover{
  color:white;
  border:none;
}
.btn{
  color:white !important;
  border:none !important;
  border-radius: 0 !important
}
@media(max-width: 988px){
  .button_header{
    width:70px;
    background-color: #DADADA;
  }
}
@media all and (max-width: 575px) {
  .button_card {
    width:30px;
    height:30px;
    margin-top:10px;
    padding-top:3px;
  }
  .btn{
    width:180px !important;
  }
}
button:active, button:focus {
  outline: none !important;
}
button::-moz-focus-inner {
  border: 0 !important;
}