@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500&display=swap" rel="stylesheet')
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto:wght@900&display=swap');
.modalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modalWindow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1C1D26;
  min-height: 600px;
  width: 500px;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
}
@media(max-width: 768px){
  .modalWindow {
    width:400px;
  }
}
.modalHeader{
  margin-top: 50px;
  margin-left: 50px;
  color: #DADADA;
  font-size: 24px;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}
.icon_times{
  margin-top:-10px;
  cursor: pointer;
  color:#DADADA;
  transform: translate(0%, 0%);
  transition: .5s;
}
.icon_times:hover{
  color: #E93F35;
  transform: rotate(90deg);
}

.modalBody{
  color:white;
}
.modalBody span:first-child{
  font-family: 'Dancing Script';
}

