.text{
  color:#B19386;
}
h4{
  color:#4A4A4A;
}
.width{
  width:100%;
}
.first_row{
  margin-left:20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.second_row{
  margin-left:20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;;
}
.item_2, .item_3, .item_1{
  height:290px;
  position: relative;
  transition: all 0.6s;
}
.item_4, .item_5{
  height:205px;
  position: relative;
  transition: all 0.6s;
}
.item_1::before, .item_2::before, .item_3::before, .item_4::before,  .item_5::before{
  content: "";
  width: 0;
  height: 0.4em;
  position: absolute;
  bottom: 0;
  left: 0;
  background: blue;
  transition: all 0.6s;
}
.item_1:hover::before, .item_2:hover::before, .item_3:hover::before, .item_4:hover::before, .item_5:hover::before {
  width: 100%;
  left: 0;
  background: #EC6448;
}
.item_1 img{
  width:250px;
  height:230px;
}
.item_3 img, .item_4 img, .item_2 img, .item_5 img{
  width:350px;
}
.item_5 img{
  height:170px;
}
@media all and (max-width: 575px) {
  .first_row {
    display: flex;
    flex-direction:column;
  }
 .second_row {
    display: flex;
    flex-direction:column;
  }
}



