@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap" rel="stylesheet");
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400@import url('https://fonts.googleapis.com/css2?family=roboto+condensed&family=roboto:wght@900&display=swap')500&display=swap" rel="stylesheet);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
.container-wrapper{
  display: grid;
  grid-template-areas:
    'business_card header'
    'business_card article';
  grid-template-rows: 80px 1fr;
  grid-template-columns: 30%  1fr;
  grid-gap: 10px;
  height: 100vh;
}

@media all and (max-width: 575px) {
  .container-wrapper {
    grid-template-areas:
      "business_card"
      "header"
      "article";
    grid-template-rows: 400px 1fr 1fr;
    grid-template-columns: 390px;
  }
}

.wrapper-content{
  grid-area: article;
}

.businessCard_card__3IE6f{
  grid-area: business_card;
  background-color: #1D1E26;
  box-shadow: 15px 0px 8px -4px rgba(50, 50, 50, 0.75);
}
.businessCard_title__2w50q{
  padding-top: 50px;
  text-align: center;
}
.businessCard_title__2w50q h2{
  color:white;
  font-family: 'Raleway', sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
}
.businessCard_title__2w50q img{
  height: 150px;
  border-radius:50%;
  margin-bottom: 15px;
}
.businessCard_title__2w50q span{
  color:white;
  font-size:20px;
  font-family: 'Roboto';
}
.businessCard_title__2w50q i {
  color:white;
  margin-left:5px;
}

.businessCard_parallax__elem1__1m6im {
  margin-top:20px;
  height:30px;
  margin-left:10px;
}
.businessCard_parallax__elem2__fEkOq {
  float: right;
  position: absolute;
  color:red;
  margin-top: 200px;
  height:30px;
}
.businessCard_parallax__elem3__11IIm{
  position: absolute;
  margin-top: 100px;
  height: 40px;
  right: 0;
}
.businessCard_parallax__elem4__1aINj{
  position: absolute;
  top: 400px;
  right: 0;
  height:50px;
}
.businessCard_block_button__3BvUW{
  border:1px solid red;
  width:180px;
}
@media all and (max-width: 575px) {
  .businessCard_parallax__elem4__1aINj{
    display: none;
  }

}

.button_card::before{
  background-image: repeating-linear-gradient(to left, #242961, #194A7A);
  border-radius: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .5s ease-in-out;
}

.button_card:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}
.button_card{
  margin-top:40px;
  transform: translate(0%, 0%);
  min-width: 150px;
  height: 40px;
  text-align: center;
  font-family: sans-serif;
  color: #fff;
  text-decoration: none;
  letter-spacing: 4px;
  transition: .5s;
  background: linear-gradient(to left, #EC6448,#E93F35);
  border:none;
}
.button_header{
  margin-left:30px;
  margin-top:-1px;
  padding-left:2px;
  font-size: 18px;
  position: relative;
  transition: all 0.6s;
  border:none;
  color: black;
  background-color:#fff;
}
.button_header::before{
  content: "";
  width: 0;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  left: 0;
  background: blue;
  transition: all 0.6s;
}
.button_header:hover::before{
  width: 100%;
  left: 0;
  background: #EC6448;
}
.btn[disabled] {
  opacity: .5 ;
  cursor: not-allowed;
  color:white;
  border:none;
  border-radius: 0;
}
.btn[disabled]:hover{
  color:white;
  border:none;
}
.btn{
  color:white !important;
  border:none !important;
  border-radius: 0 !important
}
@media(max-width: 988px){
  .button_header{
    width:70px;
    background-color: #DADADA;
  }
}
@media all and (max-width: 575px) {
  .button_card {
    width:30px;
    height:30px;
    margin-top:10px;
    padding-top:3px;
  }
  .btn{
    width:180px !important;
  }
}
button:active, button:focus {
  outline: none !important;
}
button::-moz-focus-inner {
  border: 0 !important;
}
.modal_modalOverlay__q9UDN {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal_modalWindow__ajHTH {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1C1D26;
  min-height: 600px;
  width: 500px;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
}
@media(max-width: 768px){
  .modal_modalWindow__ajHTH {
    width:400px;
  }
}
.modal_modalHeader__Ukaot{
  margin-top: 50px;
  margin-left: 50px;
  color: #DADADA;
  font-size: 24px;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}
.modal_icon_times__1DiSY{
  margin-top:-10px;
  cursor: pointer;
  color:#DADADA;
  transform: translate(0%, 0%);
  transition: .5s;
}
.modal_icon_times__1DiSY:hover{
  color: #E93F35;
  transform: rotate(90deg);
}

.modal_modalBody__3JgG-{
  color:white;
}
.modal_modalBody__3JgG- span:first-child{
  font-family: 'Dancing Script';
}


.form_demoForm__Z0keC {
  width: 500px;
  margin:15px;
}
.form_form_elem__25brZ{
  background-color: #1C1D26 !important;
  outline: none !important;
  border:none;
  width:400px;
  text-decoration: none !important;
  color: white !important;
}
.form_form_border__2bNi9{
  width:400px;
  position: relative;
  transition: all 0.6s;
}
.form_form_border__2bNi9::before{
  content: "";
  width: 100%;
  height: 0.1em;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to left, #EC6448,#E93F35);
  transition: all 0.6s;
}
.form_form_border__2bNi9:hover::before{
  width: 100%;
  left: 0;
  background: repeating-linear-gradient(to left, #242961, #194A7A);
}
@media(max-width: 768px){
  .form_form-elem__1mM_0 {
    width:200px;
  }
  .form_form_border__2bNi9{
    width: 200px;
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #1C1D26 !important;
  -webkit-text-fill-color:  white;
}
.header_header__v9FKw{
  grid-area: 'header';
}
a.header_active__YaTf6{
  color: #EA4C3C !important;
}
.header_link__3CRBA{
  color:black !important;
  margin-left:30px;
  font-size: 18px;
  position: relative;
  transition: all 0.6s;
}
a:hover{
  text-decoration: none;
}
@media(max-width: 988px){
  .header_link__3CRBA{
    width:100px;
  }
  .header_navbar__1S2KZ{
    background: #DADADA;
  }
}

.content_block_about__1GPWu{
  width:700px;
  margin-left:40px;
}
.content_text_orange__17IXr{
  color:#EA4C3C;
}
.content_block_skills__14Vlr{
  margin-left:20px;
  width:350px;
  float: left;
  margin-top:20px;
}
.content_block_education__2rjMI{
  float: left;
  width:350px;
  margin-top: 20px;
}
.content_block_language__WEtG1{
  width:350px;
  margin-left:30px;
}

h4 span{
  color:blue;
  font-size: 40px;
}
@media all and (max-width: 575px) {
  .content_block_about__1GPWu {
    width:350px;
  }
 .content_block_education__2rjMI{
   margin-left:20px;
  }
 .content_block_language__WEtG1{
   margin-left:20px;
  }
}
.experiments_text__3WD10{
  color:#B19386;
}
h4{
  color:#4A4A4A;
}
.experiments_width__3e4BT{
  width:100%;
}
.experiments_first_row__36rmc{
  margin-left:20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.experiments_second_row__LQtdZ{
  margin-left:20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;;
}
.experiments_item_2__hzpaY, .experiments_item_3__3hTQn, .experiments_item_1__3oz5o{
  height:290px;
  position: relative;
  transition: all 0.6s;
}
.experiments_item_4__1o8BE, .experiments_item_5__zCNmu{
  height:205px;
  position: relative;
  transition: all 0.6s;
}
.experiments_item_1__3oz5o::before, .experiments_item_2__hzpaY::before, .experiments_item_3__3hTQn::before, .experiments_item_4__1o8BE::before,  .experiments_item_5__zCNmu::before{
  content: "";
  width: 0;
  height: 0.4em;
  position: absolute;
  bottom: 0;
  left: 0;
  background: blue;
  transition: all 0.6s;
}
.experiments_item_1__3oz5o:hover::before, .experiments_item_2__hzpaY:hover::before, .experiments_item_3__3hTQn:hover::before, .experiments_item_4__1o8BE:hover::before, .experiments_item_5__zCNmu:hover::before {
  width: 100%;
  left: 0;
  background: #EC6448;
}
.experiments_item_1__3oz5o img{
  width:250px;
  height:230px;
}
.experiments_item_3__3hTQn img, .experiments_item_4__1o8BE img, .experiments_item_2__hzpaY img, .experiments_item_5__zCNmu img{
  width:350px;
}
.experiments_item_5__zCNmu img{
  height:170px;
}
@media all and (max-width: 575px) {
  .experiments_first_row__36rmc {
    display: flex;
    flex-direction:column;
  }
 .experiments_second_row__LQtdZ {
    display: flex;
    flex-direction:column;
  }
}




