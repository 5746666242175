.demoForm {
  width: 500px;
  margin:15px;
}
.form_elem{
  background-color: #1C1D26 !important;
  outline: none !important;
  border:none;
  width:400px;
  text-decoration: none !important;
  color: white !important;
}
.form_border{
  width:400px;
  position: relative;
  transition: all 0.6s;
}
.form_border::before{
  content: "";
  width: 100%;
  height: 0.1em;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to left, #EC6448,#E93F35);
  transition: all 0.6s;
}
.form_border:hover::before{
  width: 100%;
  left: 0;
  background: repeating-linear-gradient(to left, #242961, #194A7A);
}
@media(max-width: 768px){
  .form-elem {
    width:200px;
  }
  .form_border{
    width: 200px;
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #1C1D26 !important;
  -webkit-text-fill-color:  white;
}