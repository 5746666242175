.header{
  grid-area: 'header';
}
a.active{
  color: #EA4C3C !important;
}
.link{
  color:black !important;
  margin-left:30px;
  font-size: 18px;
  position: relative;
  transition: all 0.6s;
}
a:hover{
  text-decoration: none;
}
@media(max-width: 988px){
  .link{
    width:100px;
  }
  .navbar{
    background: #DADADA;
  }
}
