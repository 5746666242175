.container-wrapper{
  display: grid;
  grid-template-areas:
    'business_card header'
    'business_card article';
  grid-template-rows: 80px 1fr;
  grid-template-columns: 30%  1fr;
  grid-gap: 10px;
  height: 100vh;
}

@media all and (max-width: 575px) {
  .container-wrapper {
    grid-template-areas:
      "business_card"
      "header"
      "article";
    grid-template-rows: 400px 1fr 1fr;
    grid-template-columns: 390px;
  }
}

.wrapper-content{
  grid-area: article;
}
